import dayjs from 'dayjs'
import store from '@/store'

// 图片加载失败时的默认加载图片
export const imgerror = {
  inserted(el, { value }) {
    el.onerror = () => {
      el.src = value || 'https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1907773663,546655544&fm=11&gp=0.jpg'
    }
  }
}

// 页面水印
export const watermark = (el, binding) => {
  const info = store.state.user.profile
  function addWaterMarker(parentNode) {
    // str1、str2水印文字，父元素，字体，文字颜色
    const img = document.createElement('canvas') // 创建canvas元素
    parentNode.appendChild(img)
    img.width = 180
    img.height = 200
    img.style.display = 'none'
    const imgs = img.getContext('2d') // 获取canvas画布
    imgs.rotate((-20 * Math.PI) / 180) // 逆时针旋转π/9
    imgs.font = '16px Microsoft JhengHei' || '16px Microsoft JhengHei'
    imgs.fillStyle = '#cccccc'
    imgs.textAlign = 'left'
    imgs.textBaseline = 'Middle'
    imgs.fillText(info.userName + dayjs().format('YYYYMMDD'), 0, 80) // 第一行字体
    // imgs.fillText(str2, 30, 100) // 第二行字体
    parentNode.style.backgroundImage = 'url(' + img.toDataURL('image/png') + ')'
  }

  addWaterMarker(el)
}
