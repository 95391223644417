// 合同查询权限路由
export default [
  {
    path: '/workTable/agreementAddressBook',
    component: () => import('@/views/Layout/workTable/business/agreementAddressBook'),
    meta: { isKeepAlive: true }

  },
  {
    path: '/workTable/agreementResult',
    name: 'agreementResult',
    component: () => import('@/views/Layout/workTable/business/agreementAddressBook/component/agreementResult.vue'),
    meta: { isKeepAlive: true },
    props: true
  },
  {
    path: '/workTable/agreementAddressInfo/:id',
    name: 'agreementAddressInfo',
    component: () => import('@/views/Layout/workTable/business/agreementAddressBook/component/agreementAddressInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/agreementChangeAddressInfo/:id/:version',
    name: 'agreementChangeAddressInfo',
    component: () => import('@/views/Layout/workTable/business/agreementAddressBook/component/agreementChangeAddressInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  }
]
