
import commonNavBar from './commonNavBar/index.vue'
import userJump from './userJump/index.vue'

export default {
  // instal会在Vue.use时,自动调用
  install(Vue) {
    Vue.component('commonNavBar', commonNavBar)
    Vue.component('userJump', userJump)
  }
}
