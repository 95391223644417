import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Notify } from 'vant'
import { menuKeyList } from '@/units/commonUnit'
Vue.use(VueRouter)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch(err => err)
}
const constantRoutes = [
  {
    path: '/loginPage',
    component: () => import('@/views/Login/loginPage/index.vue')
  },
  {
    path: '/accountLogin',
    component: () => import('@/views/Login/accountLogin/index.vue')
  },
  {
    path: '/forgotPassword',
    component: () => import('@/views/Login/forgotPassword/index.vue')
  },
  {
    path: '/captcha',
    component: () => import('@/views/Login/forgotPassword/components/captcha.vue')
  },
  {
    path: '/resetPassword',
    component: () => import('@/views/Login/forgotPassword/components/resetPassword.vue')
  },
  {
    path: '/firstChangePassword',
    component: () => import('@/views/Login/forgotPassword/components/firstChangePassword.vue')
  },
  {
    path: '/',
    component: () => import('@/views/Layout/index.vue'),
    children: [
      { path: '/message', component: () => import('@/views/Layout/message/index.vue'), meta: { isKeepAlive: true } },
      { path: '/addressBook', component: () => import('@/views/Layout/addressBook/index.vue'), meta: { isKeepAlive: true } },
      { path: '/', component: () => import('@/views/Layout/workTable/index.vue') },
      { path: '/my', component: () => import('@/views/Layout/my/index.vue') }
    ]
  },
  {
    path: '/userInfo',
    component: () => import('@/views/Layout/my/components/userInfo.vue')
  },
  {
    path: '/messageSwitch',
    component: () => import('@/views/Layout/my/components/messageSwitch.vue')
  },
  {
    path: '/about',
    component: () => import('@/views/Layout/my/components/about.vue')
  },
  {
    path: '/update',
    component: () => import('@/views/Layout/my/components/update.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/update/updateInfo/:id',
    component: () => import('@/views/Layout/my/components/updateInfo.vue'),
    props: true
  },
  {
    path: '/accountInfo',
    component: () => import('@/views/Layout/my/components/accountInfo.vue')
  },
  {
    path: '/changePassword',
    component: () => import('@/views/Layout/my/components/changePassword.vue')
  },
  {
    path: '/personalAddressInfo/:id',
    component: () => import('@/views/Layout/addressBook/components/personalAddressInfo.vue'),
    props: true
  },
  {
    path: '/userSearch',
    component: () => import('@/views/Layout/workTable/userSearch/index.vue')
  },
  {
    path: '/searchResult',
    component: () => import('@/views/Layout/workTable/userSearch/component/searchResult.vue')
  },
  // 任务路由
  {
    path: '/workTable/task/pendingTaskList',
    component: () => import('@/views/Layout/workTable/task/list/pending.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/task/processedTaskList',
    component: () => import('@/views/Layout/workTable/task/list/processed.vue')
  },
  {
    path: '/workTable/task/initiatedTaskList',
    component: () => import('@/views/Layout/workTable/task/list/initiated.vue')
  },
  {
    path: '/workTable/task/archivedTaskList',
    component: () => import('@/views/Layout/workTable/task/list/archived.vue'),
    meta: { isKeepAlive: true }
  },
  // 消息
  {
    path: '/message/systemMessage/:type',
    name: 'systemMessage',
    component: () => import('@/views/Layout/message/components/message.vue'),
    props: true,
    meta: { isKeepAlive: true }
  },
  // 选人
  {
    path: '/commonAddressBook',
    component: () => import('@/components/commonAddressBook/index.vue')
  },
  // 404
  {
    path: '/404',
    component: () => import('@/views/404.vue')
  }
]

const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [...constantRoutes]
})

const router = createRouter()
// 导入权限路由
const req = require.context('./modules', false, /\.js$/)

// 前置导航守卫
const whiteList = ['/loginPage', '/accountLogin', '/forgotPassword', '/captcha', '/resetPassword']
router.beforeEach(async (to, from, next) => {
  const token = store.state.user.tokenInfo || localStorage.getItem('token')
  if (token) {
    if (whiteList.includes(to.path)) {
      next('/')
    } else {
      if (!store.state.user.profile.id) {
        await store.dispatch('user/getUserProfile')
        const keyList = menuKeyList(store.state.user.profile.applicationList)
        if (store.state.user.profile.id && store.state.user.profile.firstLogin === false) {
          req.keys().forEach(name => {
            if (
              keyList.includes(name.substring(2).split('.')[0])
            ) {
              req(name).default.forEach(item => {
                router.addRoute(item)
              })
            }
          })
          store.dispatch('count/getMessageCenList')
          store.dispatch('message/getMessgae')
          store.dispatch('user/getButtonJurisdiction')
        }
        router.addRoute({ path: '*', redirect: '/404' })
        next({
          ...to, // next({ ...to })的目的,是保证路由添加完了再进入页面 (可以理解为重进一次)
          replace: true // 重进一次, 不保留重复历史
        })
        return
      }
      if (store.state.user.profile.firstLogin === true && to.path !== '/firstChangePassword') {
        next('/firstChangePassword')
        return
      }
      if (store.state.user.profile.firstLogin === false && to.path === '/firstChangePassword') {
        next('/')
        return
      }
      if (!to.matched.length) {
        next({ path: '/404' })
        return
      }
      next()
    }
  } else {
    Notify.clear()
    if (whiteList.includes(to.path)) {
      next()
    } else {
      if (to.query.backTo) {
        next({
          path: '/loginPage',
          query: {
            backTo: to.backTo
          }
        })
      } else {
        next({
          path: '/loginPage',
          query: {
            backTo: to.fullPath
          }
        })
      }
    }
  }
})
// 后置导航守卫
router.afterEach((to, from) => {

})
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
