// 供应商查询权限路由
export default [
  {
    path: '/workTable/supplierAddressBook',
    component: () => import('@/views/Layout/workTable/addressBook/supplierAddressBook/index.vue')
  },
  {
    path: '/workTable/supplierResult',
    component: () => import('@/views/Layout/workTable/addressBook/supplierAddressBook/component/supplierResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/supplierAddressInfo/:id',
    component: () => import('@/views/Layout/workTable/addressBook/supplierAddressBook/component/supplierAddressInfo.vue'),
    props: true
  }
]
