// 查单位物联权限路由
export default [
  {
    path: '/workTable/companyInternetAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/companyInternetAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyInternetSystemCode',
    component: () => import('@/views/Layout/workTable/equipment/companyInternetAddressBook/component/companyInternetSystemCode.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyInternetResult',
    component: () => import('@/views/Layout/workTable/equipment/companyInternetAddressBook/component/companyInternetResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyInternetAddressBookInfo/:id',
    name: 'companyInternetAddressBookInfo',
    component: () => import('@/views/Layout/workTable/equipment/companyInternetAddressBook/component/companyInternetAddressBookInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  }
]
