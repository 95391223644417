// 卡号查询权限路由
export default [
  {
    path: '/workTable/cardNumberAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/cardNumberAddressBook/index.vue')
  },
  {
    path: '/workTable/cardNumberResult',
    component: () => import('@/views/Layout/workTable/equipment/cardNumberAddressBook/component/cardNumberResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/cardNumberAddressInfo/:id',
    component: () => import('@/views/Layout/workTable/equipment/cardNumberAddressBook/component/cardNumberAddressInfo.vue'),
    props: true

  }
]
