// 单位查询权限路由
export default [
  {
    path: '/workTable/companyAddressBook',
    component: () => import('@/views/Layout/workTable/addressBook/companyAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyResult',
    component: () => import('@/views/Layout/workTable/addressBook/companyAddressBook/component/companyResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyAddressInfo/:id',
    name: 'companyAddressInfo',
    component: () => import('@/views/Layout/workTable/addressBook/companyAddressBook/component/companyAddressInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  }
]
