import http from '@/units/request'
import axios from 'axios'
let cancel = null
let messageCenListcancel = null
/** *
 * 获取消息列表
 * ***/
export const getMessageList = (data) => {
  if (cancel !== null) {
    cancel('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/message/messageCenter/v2/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      cancel = c
    })
  })
}
/** *
 * 获取消息分类统计
 * ***/
export const getMessageCenList = () => {
  if (messageCenListcancel !== null) {
    messageCenListcancel('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/message/messageCenter/v2/messageCount',
    method: 'get',
    cancelToken: new axios.CancelToken(function (c) {
      messageCenListcancel = c
    })
  })
}
/** *
 * 消息已读
 * ***/
export const readMessage = (data) => {
  return http({
    url: '/message/userMessage/v2/messageRead',
    method: 'post',
    data
  })
}
/** *
 * 消息清空
 * ***/
export const removeMessage = (data) => {
  return http({
    url: '/message/userMessage/v2/removeAll',
    method: 'post',
    data
  })
}
/** *
 * 获取当前有效公告列表
 * ***/
export const getNoticeList = () => {
  return http({
    url: '/message/notice/v2/selectNowNoticeList',
    method: 'get'
  })
}
