// 终端定位权限路由
export default [
  {
    path: '/workTable/companyTerminalAddAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/companyTerminalAddAddressBook/index.vue')
  },
  {
    path: '/workTable/companyTerminalAddResult',
    component: () => import('@/views/Layout/workTable/equipment/companyTerminalAddAddressBook/component/companyTerminalAddResult.vue'),
    meta: { isKeepAlive: true }
  }
]
