import { reqGetProfile } from '@/api/user'
import { getToken, setToken, removeToken } from '@/units/auth'
import { Toast } from 'vant'
import router from '@/router'
import { getButtonJurisdiction } from '@/api/system'

const state = {
  tokenInfo: getToken() || null,
  profile: {},
  activeKey: 0,
  jurisdiction: []
}
const mutations = {
  // 存储token
  setTokenInfo(state, newTokenInfo) {
    state.tokenInfo = newTokenInfo
    setToken(newTokenInfo)
  },
  // 清除存储token
  removeTokenInfo(state) {
    state.tokenInfo = null
    removeToken()
  },
  // 存储用户资料
  setUserProfile(state, newUserProfile) {
    state.profile = newUserProfile
  },
  // 清除用户资料
  removeUserProfile(state) {
    state.profile = {}
  },
  // 存储用户权限
  setJurisdiction(state, newjurisdiction) {
    state.jurisdiction = newjurisdiction
  },
  // 更新激活的key
  updateActiveKey(state, val) {
    state.activeKey = val
  }

}
const actions = {
  // 获取用户资料
  async getUserProfile(context) {
    try {
      const res = await reqGetProfile()
      const obj = {
        ...res.data.data
      }
      context.commit('setUserProfile', obj)
      return obj
    } catch (error) {
      if (error?.response?.status === 401) {
        Toast.fail({
          message: '登录已过期,请重新登录!',
          duration: 500
        })
        context.commit('removeTokenInfo')
        context.commit('removeUserProfile')
        if (router.currentRoute.query.backTo) {
          router.push({
            path: '/loginPage',
            query: {
              backTo: router.currentRoute.query.backTo
            }
          })
        } else {
          router.push({
            path: '/loginPage',
            query: {
              backTo: router.currentRoute.fullPath
            }
          })
        }
        return
      }
      Toast.fail('获取用户信息失败!')
      context.commit('removeTokenInfo')
      context.commit('removeUserProfile')
      if (router.currentRoute.query.backTo) {
        router.push({
          path: '/loginPage',
          query: {
            backTo: router.currentRoute.query.backTo
          }
        })
      } else {
        router.push({
          path: '/loginPage',
          query: {
            backTo: router.currentRoute.fullPath
          }
        })
      }
    }
  },
  // 获取用户按钮权限
  async getButtonJurisdiction(context) {
    try {
      const res = await getButtonJurisdiction()
      const arr = res.data.data.map(item => item.key)
      context.commit('setJurisdiction', arr)
    } catch (error) {
    }
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
