<template>
  <div id="app">
    <keep-alive :max="5">
      <router-view v-if="$route.meta.isKeepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.isKeepAlive"></router-view>
    <van-dialog
      v-model="show"
      :title="updateInfo.versionNumber + '版本更新'"
      :message="updateInfo.description"
      confirm-button-color="#1989fa"
      :confirm-button-text="text"
      :close-on-popstate="false"
      :class="{ dialogApp: text !== '确认' }"
      @confirm="onConfirm"
      :before-close="beforeClose"
    >
      <template #default>
        <p class="updateTitleApp">更新内容:</p>
        <van-field
          safe-area-inset-bottom
          v-model="updateInfo.description"
          autosize
          readonly
          type="textarea"
          class="updateInfoApp"
        />
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getUpdateInfo, getJsapiTicket } from '@/api/system'
import { userReadVerson } from './api/user'
import wx from 'weixin-js-sdk'
let timeId = null

export default {
  name: 'app',
  data() {
    return {
      show: false,
      text: '确认(10)',
      updateInfo: {},
      value: /wxwork/i.test(navigator.userAgent)
    }
  },
  computed: {
    ...mapState('message', ['message']),
    ...mapState('user', ['tokenInfo', 'profile'])
  },
  methods: {
    ...mapActions('message', ['getMessgae']),
    ...mapActions('user', ['getUserProfile']),
    onConfirm() {
      if (this.text !== '确认') {
        return
      }
      clearInterval(timeId)
    },
    async beforeClose(action, done) {
      if (action === 'confirm') {
        if (this.text !== '确认') {
          done(false)
        } else {
          await userReadVerson({ id: this.profile.versionId })
          await this.getUserProfile()
          done()
        }
      }
    }
  },
  watch: {
    $route: {
      handler: async function (val, oldVal) {
        const whiteList = [
          '/loginPage',
          '/accountLogin',
          '/forgotPassword',
          '/captcha',
          '/resetPassword',
          '/firstChangePassword'
        ]
        const infoList = [
          '/workTable/companyAddressInfo',
          '/workTable/contactAddressInfo',
          '/workTable/invoiceInfoAddressInfo',
          '/workTable/supplierAddressInfo',
          '/workTable/companyTerminalAddressBookInfo',
          '/workTable/hostModelAddressBookInfo',
          '/workTable/companyInternetAddressBookInfo',
          '/workTable/internetModelAddressInfo',
          '/personalAddressInfo'
        ]
        if (this.value) {
          getJsapiTicket({ url: location.href.split('#')[0] }).then(
            ({ data }) => {
              wx.config({
                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.data.appId, // 必填，企业微信的corpID
                timestamp: data.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
                signature: data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: [
                  'onMenuShareAppMessage',
                  'openLocation',
                  'checkJsApi',
                  'selectEnterpriseContact'
                ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
              })
              wx.ready(function () {
                if (!infoList.includes(val.path)) {
                  wx.onMenuShareAppMessage({
                    title: 'FAS业务流程信息管理软件', // 分享标题
                    desc: '智慧FAS 智造未来', // 分享描述
                    link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                    imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
                    enableIdTrans: 1, // 是否开启id转译，不填默认为0
                    success: function () {
                      // 用户确认分享后执行的回调函数
                    },
                    error: function (res) {
                      alert('暂无分享权限')
                    },
                    cancel: function () {
                      // 用户取消分享后执行的回调函数
                    }
                  })
                }
              })
            }
          )
        }

        if (!whiteList.includes(val.path) && !timeId) {
          if (this.profile.versionLock) {
            this.text = '确认(10)'
            let time = 10
            const { data } = await getUpdateInfo(this.profile.versionId)
            if (data.data) {
              this.updateInfo = data.data
            }
            this.show = true
            timeId = setInterval(() => {
              time--
              this.text = `确认${time ? `(${time})` : ''}`
              if (time === 0) {
                clearInterval(timeId)
                timeId = null
              }
            }, 1000)
          }
        }
        if (whiteList.includes(val.path)) {
          if (this.show) {
            this.show = false
            clearInterval(timeId)
            timeId = null
          }
        }
      },
      immediate: true
    },
    message: {
      handler(val, oldVal) {
        if (this.profile.switch.messageNotice) {
          this.$notify({
            type: 'primary',
            message: `你收到一条${val.name}`,
            onClick: () => {
              if (
                this.$route.path !== '/message' &&
                !this.$route.path.includes('/message/systemMessage')
              ) {
                this.$router.push({
                  name: 'systemMessage',
                  params: {
                    Refresh: true,
                    type: val.code
                  }
                })
                this.$notify.clear()
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
