// 查消防主机权限路由
export default [
  {
    path: '/workTable/hostModelAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/hostModelAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/hostModelResult',
    component: () => import('@/views/Layout/workTable/equipment/hostModelAddressBook/component/hostModelResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/hostModelAddressBookInfo/:id',
    component: () => import('@/views/Layout/workTable/equipment/hostModelAddressBook/component/hostModelAddressInfo.vue'),
    props: true

  }
]
