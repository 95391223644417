// 查单位终端权限路由
export default [
  {
    path: '/workTable/companyTerminalAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/companyTerminalAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyTerminalResult',
    component: () => import('@/views/Layout/workTable/equipment/companyTerminalAddressBook/component/companyTerminalResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/companyTerminalAddressBookInfo/:id',
    name: 'companyTerminalAddressBookInfo',
    component: () => import('@/views/Layout/workTable/equipment/companyTerminalAddressBook/component/companyTerminalAddressBookInfo.vue'),
    meta: { isKeepAlive: true },
    props: true

  }
]
