import dayjs from 'dayjs'
export function formatGender(val) {
  if (val === 0) {
    return '男'
  } else if (val === 1) {
    return '女'
  } else if (val === 2) {
    return '未知'
  } else {
    return '--'
  }
}
export function formatTelephone(val) {
  return val.replace(/(\d{3})(\d{4})/, '$1 $2 ')
}
export function formatregisteredCapital(val) {
  if (val === '--') {
    return val
  } else {
    return val + '万元'
  }
}
export const timeFormat = (val) => {
  // 1.0分钟<时间<=1分钟:显示格式：刚刚
  // 2.1分钟<=时间<=30分钟：显示格式 xxx分钟前
  // 3.时间>30分钟：显示格式 16:00
  // 4.日期过了当天：显示格式：昨天10:12
  // 5.日期过了昨天：显示x月x日
  const difDate = +dayjs().valueOf() - +dayjs(val).valueOf()
  if (+dayjs().format('YYYYMMDD') - +dayjs(val).format('YYYYMMDD') === 1) {
    return `昨天${dayjs(val).format('HH:mm')}`
  }
  if (+dayjs().format('YYYYMMDD') - +dayjs(val).format('YYYYMMDD') > 1) {
    return dayjs(val).format('YYYY/MM/DD')
  }
  if (difDate / 1000 > 0 && difDate / 1000 <= 60) {
    return '刚刚'
  }
  if (
    Math.floor(difDate / 1000 / 60) >= 1 && Math.floor(difDate / 1000 / 60) <= 30
  ) {
    return `${Math.floor(difDate / 1000 / 60)}分钟前`
  }
  if (
    Math.floor(difDate / 1000 / 60) > 30
  ) {
    return dayjs(val).format('HH:mm')
  }
}
// 格式化日期为'YYYY-MM-DD'
export const openYmdFormat = (val) => {
  if (val) {
    return dayjs(val).format('YYYY-MM-DD')
  } else {
    return '--'
  }
}
// 格式化日期为 'YYYY-MM-DD HH:mm:ss'
export const dateFormat = (val) => {
  if (val) {
    return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
  } else {
    return '--'
  }
}
// 格式化日期为 'YYYY年'
export const yearFormat = (val) => {
  if (val) {
    return dayjs(val).format('YYYY年')
  } else {
    return '--'
  }
}
// 格式化金额
export const moneyFormat = (val) => {
  if (val) {
    if (val.includes('.')) {
      const str1 = val.split('.')[0].replace(/(?=\B(\d{3})+$)/g, ',')
      const str2 = val.split('.')[1]
      return `¥ ${str1}.${str2}`
    } else {
      return `¥ ${val.replace(/(?=\B(\d{3})+$)/g, ',')}`
    }
  } else {
    return '--'
  }
}
