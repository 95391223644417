// 查施工协议权限路由
export default [
  {
    path: '/workTable/constructionAddressBook',
    component: () => import('@/views/Layout/workTable/business/constructionAddressBook'),
    meta: { isKeepAlive: true }

  },
  {
    path: '/workTable/constructionResult',
    name: 'constructionResult',
    component: () => import('@/views/Layout/workTable/business/constructionAddressBook/component/constructionResult.vue'),
    meta: { isKeepAlive: true },
    props: true
  },
  {
    path: '/workTable/constructionAddressInfo/:id',
    name: 'constructionAddressInfo',
    component: () => import('@/views/Layout/workTable/business/constructionAddressBook/component/constructionAddressInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  }
]
