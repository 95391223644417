// 地址定位权限路由
export default [
  {
    path: '/workTable/companyAddAddressBook',
    component: () => import('@/views/Layout/workTable/addressBook/companyAddAddressBook/index.vue')
  },
  {
    path: '/workTable/companyAddResult',
    component: () => import('@/views/Layout/workTable/addressBook/companyAddAddressBook/component/companyAddResult.vue'),
    meta: { isKeepAlive: true }
  }
]
