import Vue from 'vue'
import {
  Button,
  Icon,
  NavBar,
  Field,
  CellGroup,
  Toast,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  List,
  Cell,
  Grid,
  GridItem,
  Image as VanImage,
  PullRefresh,
  Lazyload,
  Popup,
  ActionSheet,
  Search,
  Loading,
  Divider,
  Tag,
  Row,
  Col,
  DatetimePicker,
  Uploader,
  Switch,
  Popover,
  Form,
  CountDown,
  Sidebar,
  SidebarItem,
  PasswordInput,
  NumberKeyboard,
  IndexBar,
  IndexAnchor,
  Empty,
  Collapse,
  CollapseItem,
  NoticeBar,
  ImagePreview,
  Picker,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  CheckboxGroup,
  Badge,
  Dialog,
  Overlay,
  Calendar,
  Notify,
  SwipeCell,
  RadioGroup,
  Radio,
  TreeSelect,
  Swipe,
  SwipeItem
} from 'vant'
Vue.use(Button)
  .use(Icon)
  .use(NavBar)
  .use(Field)
  .use(CellGroup)
  .use(Toast)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Tab)
  .use(Tabs)
  .use(List)
  .use(Cell)
  .use(GridItem)
  .use(Grid)
  .use(VanImage)
  .use(PullRefresh)
  .use(Lazyload)
  .use(Popup)
  .use(ActionSheet)
  .use(Search)
  .use(Loading)
  .use(Divider)
  .use(Tag)
  .use(Row)
  .use(Col)
  .use(Dialog)
  .use(DatetimePicker)
  .use(Uploader)
  .use(Switch)
  .use(Popover)
  .use(Form)
  .use(CountDown)
  .use(Sidebar)
  .use(SidebarItem)
  .use(PasswordInput)
  .use(NumberKeyboard)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Empty)
  .use(Collapse)
  .use(CollapseItem)
  .use(NoticeBar)
  .use(ImagePreview)
  .use(Picker)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Badge)
  .use(Overlay)
  .use(Calendar)
  .use(Notify)
  .use(SwipeCell)
  .use(Radio)
  .use(RadioGroup)
  .use(TreeSelect)
  .use(Swipe)
  .use(SwipeItem)
