<template>
  <van-cell
    center
    :title="title"
  >
    <template #default>
      <div v-if="userList && userList.length">
        <span
          :style="{
            color: `${item.navigator
              ? '#1990ff'
              : ''
              }`,
            paddingLeft: `${userList.length > 1 && index > 0
              ? '0.5em'
              : 'none'
              }`,
          }"
          @click="userInfoClickHandler(item.id, item.navigator)"
          v-for="(item, index) in userList"
          :key="index"
        >
          {{ item.name }}
        </span>
      </div>
      <p v-else>--</p>
    </template>
  </van-cell>
</template>

<script>
export default {
  name: 'userJump',
  props: {
    title: {
      type: String,
      required: true,
      default: '更新人:'
    },
    userList: {
      required: true,
      default: function () {
        return []
      }
    }
  },
  methods: {
    // 人员详情跳转
    userInfoClickHandler(id, navigator) {
      if (navigator) {
        this.$router.push(`/personalAddressInfo/${id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.changeLab {
  .van-cell__title {
    color: #D9001B;
  }
}
</style>
