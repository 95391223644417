// 合同计费权限路由
export default [
  {
    path: '/workTable/standardAddressBook',
    component: () => import('@/views/Layout/workTable/business/standardAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/standardResult',
    name: 'standardResult',
    component: () => import('@/views/Layout/workTable/business/standardAddressBook/component/standardResult.vue'),
    meta: { isKeepAlive: true },
    props: true
  },
  {
    path: '/workTable/frameworkResult/:id',
    name: 'frameworkResult',
    component: () => import('@/views/Layout/workTable/business/standardAddressBook/component/frameworkResult.vue'),
    meta: { isKeepAlive: true },
    props: true
  },
  {
    path: '/workTable/standardAddressInfo/:id',
    name: 'standardAddressInfo',
    component: () => import('@/views/Layout/workTable/business/standardAddressBook/component/standardAddressInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/chargingInfoList/:id',
    name: 'chargingInfoList',
    component: () => import('@/views/Layout/workTable/business/standardAddressBook/component/chargingInfoList.vue'),
    props: true
  },
  {
    path: '/workTable/historyChargingInfo/:id',
    name: 'historyChargingInfo',
    component: () => import('@/views/Layout/workTable/business/standardAddressBook/component/historyChargingInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  }
]
