<template>
  <div class="commonNavBar">
    <!-- 顶部导航栏 -->
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="backClick"
    >
      <template #right>
        <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-end"
          overlay
          :offset="[15, 8]"
        >
          <van-sidebar
            :activeKey="activeKey"
            class="mySidebar"
            @change="sidebarChange"
          >
            <!-- <van-sidebar-item badge="5" @click="pendingHandler">
              <template #title >
                <van-icon name="todo-list-o"></van-icon> <span >待处理</span>
              </template>
</van-sidebar-item> -->
            <van-sidebar-item
              :badge="count.unreadMessage"
              @click="newMessageHandler"
              to="/message"
            >
              <template #title>
                <svg-icon
                  icon-class="newMessage"
                  class="van-icon"
                />
                <span>新消息</span>
              </template>
            </van-sidebar-item>
          </van-sidebar>
          <template #reference>
            <van-icon
              name="ellipsis"
              :dot="!!count.unreadMessage"
            />
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'commonNavBar',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      showPopover: false
    }
  },
  activated() {
    this.showPopover = false
  },
  computed: {
    ...mapState('user', ['activeKey']),
    ...mapState('count', ['count'])
  },
  methods: {
    ...mapMutations('user', ['updateActiveKey']),
    // 跳转待处理
    pendingHandler() {
      this.showPopover = false
    },
    // 跳转消息
    newMessageHandler() {
      this.showPopover = false
    },
    // 顶部导航变化
    sidebarChange(index) {
      this.updateActiveKey(index)
    },
    // 返回按钮
    backClick() {
      if (window.history.length < 2) {
        this.$router.replace('/')
        return
      }
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.commonNavBar {
  ::v-deep {
    .van-icon-ellipsis {
      font-size: 20px;
    }
  }
}
</style>
