/**
* 菜单权限列表
*/
export function menuKeyList(arr) {
  if (!arr) {
    return []
  }
  // 获取路由列表
  const newArr = []
  arr.forEach(item => {
    newArr.push(...item.children)
  })
  // 获取权限列表
  const keyList = newArr.map(item => {
    return item.key
  })
  return keyList
}
