// 施工计费权限路由
export default [
  {
    path: '/workTable/constructionStandardAddressBook',
    component: () => import('@/views/Layout/workTable/business/constructionStandardAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/constructionStandardResult',
    name: 'constructionStandardResult',
    component: () => import('@/views/Layout/workTable/business/constructionStandardAddressBook/component/constructionStandardResult.vue'),
    meta: { isKeepAlive: true },
    props: true
  },
  {
    path: '/workTable/constructionFrameworkResult/:id',
    name: 'constructionFrameworkResult',
    component: () => import('@/views/Layout/workTable/business/constructionStandardAddressBook/component/constructionFrameworkResult.vue'),
    meta: { isKeepAlive: true },
    props: true
  },
  {
    path: '/workTable/constructionStandardAddressInfo/:id',
    name: 'constructionStandardAddressInfo',
    component: () => import('@/views/Layout/workTable/business/constructionStandardAddressBook/component/constructionStandardAddressInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/constructionChargingInfoList/:id',
    name: 'constructionChargingInfoList',
    component: () => import('@/views/Layout/workTable/business/constructionStandardAddressBook/component/constructionChargingInfoList.vue'),
    props: true
  },
  {
    path: '/workTable/historyconstructionChargingInfo/:id',
    name: 'historyconstructionChargingInfo',
    component: () => import('@/views/Layout/workTable/business/constructionStandardAddressBook/component/historyConstructionChargingInfo.vue'),
    props: true,
    meta: { isKeepAlive: true }
  }
]
