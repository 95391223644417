// 查设备型号权限路由
export default [
  {
    path: '/workTable/internetModelAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/internetModelAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/internetModelResult',
    component: () => import('@/views/Layout/workTable/equipment/internetModelAddressBook/component/internetModelResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/internetModelAddressInfo/:id',
    component: () => import('@/views/Layout/workTable/equipment/internetModelAddressBook/component/internetModelAddressInfo.vue'),
    props: true

  }
]
