// 开票查询权限路由
export default [
  {
    path: '/workTable/invoiceInfoAddressBook',
    component: () => import('@/views/Layout/workTable/addressBook/invoiceInfoAddressBook/index.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/invoiceInfoResult',
    component: () => import('@/views/Layout/workTable/addressBook/invoiceInfoAddressBook/component/invoiceInfoResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/invoiceInfoAddressInfo/:id',
    component: () => import('@/views/Layout/workTable/addressBook/invoiceInfoAddressBook/component/invoiceInfoAddressInfo.vue'),
    props: true

  }
]
