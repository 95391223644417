import mqtt from 'mqtt'
import store from '@/store'
const options = {
  connectTimeout: 40000,
  clientId: 'fas_is',
  username: process.env.VUE_APP_SECRET_OPTIONS_UserName,
  password: process.env.VUE_APP_SECRET_OPTIONS_Password,
  clean: true
}
let client = ''
const state = {
  message: '',
  clientCode: ''
}
const mutations = {
  // 存储消息
  setMessage(state, newMessgae) {
    state.message = newMessgae
  },
  // 判断链接客户端
  envjudge(state) {
    if (!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) && /wxwork/i.test(navigator.userAgent)) {
      state.clientCode = 'com-wx-mobile'
    } else if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      state.clientCode = 'mobile'
    } else {
      state.clientCode = 'pc'
    }
  }
}
const actions = {
  // 获取消息
  getMessgae(context) {
    context.commit('envjudge')
    try {
      options.clientId = 'fas_is' + `_${context.state.clientCode}` + `_${store.state.user.profile.id}` + `_${Date.now()}`
      client = mqtt.connect(process.env.VUE_APP_SECRET_MqttConnectUrl, options)
      // 链接消息
      client.on('connect', (e) => {
        client.subscribe(`is${store.state.user.profile.id}`, { qos: 0 }, (error) => {
          if (!error) {
          } else {
          }
        })
      })
      // 接收消息处理
      client.on('message', (topic, message) => {
        store.dispatch('count/getMessageCenList')
        context.commit('setMessage', JSON.parse(message))
      })
    } catch (error) {
      console.log(error)
    }
  },
  // 取消订阅
  destroyConnection(context) {
    client.unsubscribe(`is${store.state.user.profile.id}`, error => {
      if (!error) {
        if (client.connected) {
          try {
            client.end(true)
            client = ''
          } catch (error) {
            console.log(error)
          }
        }
      } else {
        console.log('Unsubscribe error', error)
      }
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
