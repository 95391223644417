// 物联定位权限路由
export default [
  {
    path: '/workTable/companyInternetAddAddressBook',
    component: () => import('@/views/Layout/workTable/equipment/companyInternetAddAddressBook/index.vue')
  },
  {
    path: '/workTable/companyInternetAddResult',
    component: () => import('@/views/Layout/workTable/equipment/companyInternetAddAddressBook/component/companyInternetAddResult.vue'),
    meta: { isKeepAlive: true }
  }
]
