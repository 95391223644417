// import Cookies from 'js-cookie'
const TokenKey = 'token'
// const userInfoKey = 'userInfo'

export function getToken () {
  // if (Cookies.get(TokenKey)) {
  //   localStorage.setItem(TokenKey, Cookies.get(TokenKey))
  // } else {
  //   localStorage.removeItem(TokenKey)
  //   localStorage.removeItem(userInfoKey)
  // }
  return localStorage.getItem(TokenKey)
}

export function setToken (token) {
  // Cookies.set(TokenKey, token, { domain: '.shfas96119.com' })

  return localStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return localStorage.removeItem(TokenKey)
}

// export function getUserInfo () {
//   return localStorage.getItem(userInfoKey)
// }

// export function setUserInfo (info) {
//   return localStorage.setItem(userInfoKey, info)
// }

// export function removeUserInfo () {
//   return localStorage.removeItem(userInfoKey)
// }
