import Vue from 'vue'
import { Toast } from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import '@/units/vant-ui'
import * as directives from '@/directives'
import * as filters from '@/filters'
import MyComponents from '@/components'
import '@/icons'
import '@/styles/index.scss'
import '@/styles/pcWxin.scss'
import '@vant/touch-emulator'

Vue.use(MyComponents)
Toast.setDefaultOptions({ forbidClick: true })

Vue.config.productionTip = false
// 全局指令注册
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key])
})
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
