import { getMessageCenList } from '@/api/message'

// import router from '@/router'

const state = {
  count: {},
  messageTypeList: []

}
const mutations = {
  // 设置数量
  setCount(state, newCountObj) {
    const newObj = {}
    newObj[newCountObj.countName] = newCountObj.newCount
    state.count = { ...newObj }
  },
  // 设置消息中心
  setMessage(state, newData) {
    state.messageTypeList = [...newData]
  }

}
const actions = {
  // 获取消息中心
  async getMessageCenList(context) {
    try {
      const { data } = await getMessageCenList()
      context.commit('setMessage', data.data.fasIsMessageCountVoList)
      if (data.data.allUnReadCount > 99) {
        context.commit('setCount', { countName: 'unreadMessage', newCount: '99+' })
        return
      }
      if (!data.data.allUnReadCount) {
        context.commit('setCount', { countName: 'unreadMessage', newCount: '' })
        return
      }
      context.commit('setCount', { countName: 'unreadMessage', newCount: data.data.allUnReadCount })
    } catch (error) {
      console.log(error)
    }
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
