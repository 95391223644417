import http from '@/units/request'
/** *
 * 登录
 * ***/
export const reqLogin = (data) => {
  return http({
    url: '/is-oauth/login/v2/passwordLogin',
    method: 'post',
    data
  })
}
// /** *
//  * 登出
//  * ***/
// export const reqLogout = () => {
//   return http({
//     url: '/login/v2/loginOut',
//     method: 'post'
//   })
// }
/** *
 * 第三方登录
 * ***/
export const EWechatLogin = () => {
  return http({
    url: '/is-oauth/login/v2/EWechatLogin',
    method: 'get'
  })
}
/** *
 * 获取用户信息
 * ***/
export const reqGetProfile = () => {
  return http({
    url: '/is-user/personal/v2/userInfo',
    method: 'get'
  })
}
/** *
 * 获取验证码
 * ***/
export const reqGetCode = (data) => {
  return http({
    url: `/is-oauth/password/v2/changePasswordCode/${data}`,
    method: 'get'
  })
}
/** *
 * 验证验证码
 * ***/
export const checkPhoneCode = (data) => {
  return http({
    url: '/is-oauth/password/v2/checkPhoneCode',
    method: 'post',
    data
  })
}
/** *
 * 首次登陆修改密码
 * ***/
export const firstChangePassword = (data) => {
  return http({
    url: '/is-oauth/password/v2/firstChangePassword',
    method: 'post',
    data
  })
}
/** *
 * 重置密码
 * ***/
export const changePassword = (data) => {
  return http({
    url: '/is-oauth/password/v2/changePassword',
    method: 'post',
    data
  })
}
/** *
 * 修改密码
 * ***/
export const userChangePassword = (data) => {
  return http({
    url: '/is-user/personal/v2/changePassword',
    method: 'POST',
    data
  })
}

/** *
 * 修改开关状态
 * ***/
export const userChangeSwitch = (data) => {
  return http({
    url: '/is-user/personal/v2/switch',
    method: 'POST',
    data
  })
}

/** *
 * 版本消息已读
 * ***/
export const userReadVerson = (data) => {
  return http({
    url: '/is-user/personal/v2/read',
    method: 'POST',
    data
  })
}
