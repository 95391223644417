import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import addressBook from './modules/addressBook'
import count from './modules/count'
import message from './modules/message'
import sendMessagePerson from './modules/sendMessagePerson'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    addressBook,
    message,
    count,
    sendMessagePerson
  }
})
