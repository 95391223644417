import axios from 'axios'
import store from '@/store'
import router, { resetRouter } from '@/router'
import { Toast } from 'vant'

const http = axios.create({
  baseURL: '/api/', // process.env.baseUrl, // api的base_url
  withCredentials: false,
  timeout: 10000 // 请求超时时间
})
// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.headers['System-Code'] = 0
    const token = store.state.user.tokenInfo
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = 'Bearer ' + token
    }

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response
  },
  async function (error) {
    // 对响应错误做点什么
    if (error?.response?.status === 401) {
      Toast.fail('登录已过期,请重新登录!')
      store.dispatch('message/destroyConnection')
      store.commit('user/removeTokenInfo')
      store.commit('user/removeUserProfile')
      resetRouter()
      if (router.currentRoute.query.backTo) {
        router.push({
          path: '/loginPage',
          query: {
            backTo: router.currentRoute.query.backTo
          }
        })
      } else {
        router.push({
          path: '/loginPage',
          query: {
            backTo: router.currentRoute.fullPath
          }
        })
      }
    }
    return Promise.reject(error)
  }
)
export default http
