import http from '@/units/request'
/** *
 * 获取更新日志列表
 * ***/
export const getVerisonList = () => {
  return http({
    url: '/is-system/version/v2/list',
    method: 'get'
  })
}
/** *
 * 获取更新日志内容
 * ***/
export const getUpdateInfo = (id) => {
  return http({
    url: `/is-system/version/v2/info/${id}`,
    method: 'get'
  })
}
/** *
 * 获取系统参数
 * ***/
export const getSystemInfo = (ids) => {
  return http({
    url: '/is-system/base/v2/getParamByCode',
    method: 'get',
    params: ids
  })
}
/** *
 * 获取字典表
 * ***/
export const getDictionaryByType = (data) => {
  return http({
    url: '/is-system/base/v2/getDictionaryByType',
    method: 'get',
    params: data
  })
}
/** *
 * 批量获取数据字典下拉列表
 * ***/
export const getDictionaryByTypeList = (data) => {
  return http({
    url: '/is-system/base/v2/dictionary/select',
    method: 'get',
    params: data
  })
}
/** *
 * 获取搜索历史
 * ***/
export const getSearchHistory = (searchType) => {
  return http({
    url: '/is-system/base/v2/getSearchHistory',
    method: 'get',
    params: searchType
  })
}
/** *
 * 设置搜索历史
 * ***/
export const postSearchHistory = (data) => {
  return http({
    url: '/is-system/base/postSearchHistory',
    method: 'post',
    data
  })
}
/** *
 * 获取按钮权限
 * ***/
export const getButtonJurisdiction = () => {
  return http({
    url: 'is-system/application/v2/button',
    method: 'get'
  })
}
/** *
 * 清空搜索历史
 * ***/
export const deleteSearchHistory = (searchType) => {
  return http({
    url: '/is-system/base/v2/truncateSearchHistory',
    method: 'delete',
    params: searchType
  })
}
/** *
 * 获取区域树形数据
 * ***/
export const getDistrictList = (ids) => {
  return http({
    url: '/is-system/base/v2/district/select',
    method: 'get',
    params: ids
  })
}
/** *
 * 获取联系人岗位类型下拉列表
 * ***/
export const getContactPositionList = () => {
  return http({
    url: '/is-system/base/v2/contactPosition/select',
    method: 'get'
  })
}
/** *
 * 获取JS-SDK配置信息
 * ***/
export const getJsapiTicket = (url) => {
  return http({
    url: '/is-oauth/wechat/getJsapiTicket',
    method: 'get',
    params: url
  })
}
/** *
 * 获取地址经纬度
 * ***/
export const getAddressInfo = (address) => {
  return http({
    url: '/is-system/base/v2/getAddressInfo',
    method: 'get',
    params: { address }
  })
}
