// 联系人权限路由
export default [
  {
    path: '/workTable/contactAddressBook',
    component: () => import('@/views/Layout/workTable/addressBook/contactAddressBook/index.vue'),
    meta: { isKeepAlive: true }

  },
  {
    path: '/workTable/contactResult',
    component: () => import('@/views/Layout/workTable/addressBook/contactAddressBook/component/contactResult.vue'),
    meta: { isKeepAlive: true }
  },
  {
    path: '/workTable/contactAddressInfo/:id',
    component: () => import('@/views/Layout/workTable/addressBook/contactAddressBook/component/contactAddressInfo.vue'),
    props: true

  },
  {
    path: '/workTable/contactCommonAddressBook/:type',
    component: () => import('@/views/Layout/workTable/addressBook/contactAddressBook/component/commonAddressBook.vue'),
    props: true
  },
  {
    path: '/workTable/sendMessage/:tel/:name',
    component: () => import('@/views/Layout/workTable/addressBook/contactAddressBook/component/sendMessage.vue'),
    meta: { isKeepAlive: true },
    props: true
  }
]
