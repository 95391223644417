const state = {
  selectList: []
}
const mutations = {
  // 设置选择的通讯录人员
  setSelectList (state, newList) {
    state.selectList = [...newList]
  }

}
const actions = {

}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
