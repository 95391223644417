var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-cell',{attrs:{"center":"","title":_vm.title},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.userList && _vm.userList.length)?_c('div',_vm._l((_vm.userList),function(item,index){return _c('span',{key:index,style:({
          color: `${item.navigator
            ? '#1990ff'
            : ''
            }`,
          paddingLeft: `${_vm.userList.length > 1 && index > 0
            ? '0.5em'
            : 'none'
            }`,
        }),on:{"click":function($event){return _vm.userInfoClickHandler(item.id, item.navigator)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_c('p',[_vm._v("--")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }